import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  MessageOutlined,
  LineChartOutlined,
  ReadOutlined,
  ShopOutlined,
  PictureOutlined,
  SolutionOutlined,
  GiftOutlined,
  ShoppingOutlined,
  WalletOutlined,
  PartitionOutlined,
  DollarCircleOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [

  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <LineChartOutlined />,
      label: "대시보드",
    },
    component: loadable(() => import("../pages/dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/home",
    sidebar: {
      icon: <ReadOutlined />,
      label: "홈 관리",
    },
    children: [
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "배너 관리",
        },
        component: loadable(() => import("../pages/home/banner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/modify/:id",
        component: loadable(() => import("../pages/home/banner/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/register",
        component: loadable(() => import("../pages/home/banner/Register")),
      },
      {
        exact: true,
        path: "/category",
        sidebar: {
          label:"카테고리 관리",
        },
        component: loadable(() => import("../pages/home/category/List")),
      },
      {
        exact: true,
        path: "/category/register",
        visible: false,
        component: loadable(() => import("../pages/home/category/Register")),
      },
      {
        exact: true,
        path: "/category/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/home/category/Modify")),
      },
      {
        exact: true,
        path: "/theme",
        sidebar: {
          label: "테마 관리",
        },
        component: loadable(() => import("../pages/home/theme/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/theme/detail/:id",
        component: loadable(() => import("../pages/home/theme/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/theme/register",
        component: loadable(() => import("../pages/home/theme/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/store",
    sidebar: {
      icon: <ShopOutlined />,
      label: "매장 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "매장 목록",
        },
        component: loadable(() => import("../pages/store/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/store/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/store/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/menu",
    sidebar: {
      icon: <ShoppingOutlined />,
      label: "메뉴 관리",
    },
    children: [
      {
        exact: true,
        path: "/store",
        sidebar: {
          label: "매장 목록",
        },
        component: loadable(() => import("../pages/menu/store/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/menu/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register/:id",
        component: loadable(() => import("../pages/menu/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/menu/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/contents",
    sidebar: {
      icon: <PictureOutlined />,
      label: "컨텐츠 관리",
    },
    children: [
      {
        exact: true,
        path: "/pick",
        sidebar: {
          label: "Pick 관리",
        },
        component: loadable(() => import("../pages/contents/pick/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/pick/detail/:id",
        component: loadable(() => import("../pages/contents/pick/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/pick/register",
        component: loadable(() => import("../pages/contents/pick/Register")),
      },
      {
        exact: true,
        path: "/alarm",
        sidebar: {
          label: "알림 관리",
        },
        component: loadable(() => import("../pages/contents/alarm/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/alarm/detail/:id",
        component: loadable(() => import("../pages/contents/alarm/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/alarm/register",
        component: loadable(() => import("../pages/contents/alarm/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/gift",
    sidebar: {
      icon: <GiftOutlined />,
      label: "상품권 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체 상품권 관리",
        },
        component: loadable(() => import("../pages/gift/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/store/:id",
        component: loadable(() => import("../pages/gift/Detail")),
      },
      {
        exact: true,
        path: "/admin",
        sidebar: {
          label: "관리자 발행 상품권 관리",
        },
        component: loadable(() => import("../pages/gift/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/detail/:id",
        component: loadable(() => import("../pages/gift/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/register",
        component: loadable(() => import("../pages/gift/admin/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <WalletOutlined />,
      label: "주문내역 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체 주문내역",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
      {
        exact: true,
        path: "/cancel",
        sidebar: {
          label: "취소 주문내역",
        },
        component: loadable(() => import("../pages/order/CancelList")),
      },
      {
        exact: true,
        visible: false,
        path: "/cancel/detail/:id",
        component: loadable(() => import("../pages/order/CancelDetail")),
      },
      {
        exact: true,
        path: "/refund",
        sidebar: {
          label: "환불 주문내역",
        },
        component: loadable(() => import("../pages/order/RefundList")),
      },
      {
        exact: true,
        visible: false,
        path: "/refund/detail/:id",
        component: loadable(() => import("../pages/order/RefundDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "정산 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 매장 리스트",
        },
        component: loadable(() => import("../pages/calculate/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/calculate/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/setting",
    sidebar: {
      icon: <SettingOutlined />,
      label: "설정",
    },
    children: [
      {
        exact: true,
        path: "/calculate",
        sidebar: {
          label: "정산 설정",
        },
        component: loadable(() => import("../pages/setting/calculate/List")),
      },
      {
        exact: true,
        path: "/admin",
        sidebar: {
          label: "관리자 설정",
        },
        component: loadable(() => import("../pages/setting/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/modify/:id",
        component: loadable(() => import("../pages/setting/admin/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/register",
        component: loadable(() => import("../pages/setting/admin/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/region",
    sidebar: {
      icon: <PartitionOutlined />,
      label: "지역 관리",
    },
    children: [
      {
        exact: true,
        path: "/main",
        sidebar: {
          label: "상위 지역 관리",
        },
        component: loadable(() => import("../pages/region/main/List")),
      },
      {
        exact: true,
        path: "/main/register",
        visible: false,
        component: loadable(() => import("../pages/region/main/Register")),
      },
      {
        exact: true,
        path: "/main/modify/:id",
        visible: false,
        component: loadable(() => import("../pages/region/main/Modify")),
      },
      {
        exact: true,
        path: "/sub/:id",
        visible: false,
        component: loadable(() => import("../pages/region/sub/List")),
      },
      {
        exact: true,
        path: "/sub/register/:id",
        visible: false,
        component: loadable(() => import("../pages/region/sub/Register")),
      },
      {
        exact: true,
        path: "/sub/modify/:id",
        visible: false,
        component: loadable(() => import("../pages/region/sub/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "일반 회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/withdraw",
        sidebar: {
          label: "탈퇴 회원 목록",
        },
        component: loadable(() => import("../pages/user/withdraw/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/withdraw/detail/:id",
        component: loadable(() => import("../pages/user/withdraw/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <MessageOutlined />,
      label: "고객센터 관리",
    },
    children: [
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1 문의 목록",
        },
        component: loadable(() => import("../pages/cs/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/cs/qna/Detail")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 목록",
        },
        component: loadable(() => import("../pages/cs/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/detail/:id",
        component: loadable(() => import("../pages/cs/faq/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/cs/faq/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/etc",
    sidebar: {
      icon: <SolutionOutlined />,
      label: "기타정보 관리",
    },
    component: loadable(() => import("../pages/etc/List")),
  },
/*  {
    exact: true,
    path: "/gift",
    sidebar: {
      icon: <GiftOutlined />,
      label: "선물카드 관리",
    },
    component: loadable(() => import("../pages/gift/CancelList")),
  },*/
];
